<template>
	<v-list-tile v-if="document">
		<v-list-tile-content>
			<v-list-tile-title>{{ $t('documents.depositdate') }}</v-list-tile-title>
			<v-list-tile-sub-title>
				{{ $t('documents.created_on', { date: $translateDateTime(document.created_at, 'long') }) }}
				<br />
				<span v-if="author">
					{{ $t('documents.validated_by', { user: author }) }}
				</span>
			</v-list-tile-sub-title>
		</v-list-tile-content>
	</v-list-tile>
</template>

<script>
import DocumentsManagerModuleGuard from '@/mixins/ModulesGuards/Documents/DocumentsManagerModuleGuard'

export default {
	name: 'DocumentDeposit',
	mixins: [DocumentsManagerModuleGuard],
	props: {
		value: {
			type: Object,
			required: false,
			default: null
		}
	},
	computed: {
		model: {
			get: function () {
				return this.value
			},
			set: function (value) {
				this.$emit('input', value)
			}
		},
		document: function () {
			return this.model
		},
		author: function () {
			return this.document?.author?.username
		}
	},
	watch: {
		value: {
			immediate: true,
			handler: function () {
				this.loadAuthor()
			}
		}
	},
	methods: {
		loadAuthor: function () {
			if (this.document && !this.document.author) {
				this.service.loadDocumentAuthor(this.vendorId, this.document)
			}
		}
	}
}
</script>
